.questions {
  padding-top: var(--padding-default);
}

.questions h2 {
  color: var(--color-teal);
  font-weight: 800;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.questions ul {
  padding: 0;
}

.questions a {
  text-decoration: none;
  color: var(--color-mediumseagreen);
}