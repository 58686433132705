.winGift .winGiftContainer {
  position: relative;
  overflow: hidden;
}

.winGift .backgroundImage {
  background: url('../images/green-background.jpg') no-repeat center center/cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.winGift .yellowShape {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60%;
  z-index: 1;
  transform: translateY(25%);
}

.winGift .heartShape {
  position: relative;
  background: url('../images/pink-heart.png') no-repeat center center/cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.formContainer {
  padding: 100px 20px;
}

.winGift h1 {
  font-size: 2rem;
  color: white;
  margin-bottom: 10px;
}

.winGift p {
  font-size: 1rem;
  color: white;
  margin-bottom: 20px;
}

.winGift input::placeholder {
  color: var(--color-mediumseagreen);
}

.winGift  input:focus {
    outline: none;
}

.winGift input[type="checkbox"] {
  margin-right: 1rem;
}

.winGift  label, .winGift a {
  color: white;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 4px;
  font-family: var(--font-forma-djr-display);
}

.winGift input[type="email"] {
  border: 1px solid var(--color-teal);
  padding: 1rem 2rem !important;
  font-size: 1rem !important;
  width: 100% !important;
  letter-spacing: 1px !important;
  font-weight: 500 !important;
  color: var(--color-mediumseagreen) !important;
  border-radius: 1px !important;
  margin-bottom: 1rem !important;
  position: relative !important;
  z-index: 100 !important
}

.winGift label {
  font-size: 1rem;
  color: white;
  text-align: left;
  margin-bottom: 20px;
  position: relative;
  z-index: 100;
}

.winGift label a {
  color: white;
  text-decoration: underline;
}

.winGift input[type="submit"] {
  background-color: var(--color-mediumseagreen) !important;
  color: var(--color-teal) !important;
  padding: 0.5rem 2.5rem !important;
  border: none !important;
  border-radius: 0 !important;
  font-weight: 800 !important;
  font-size: 1rem !important;
  cursor: pointer;
  z-index: 5;
  position: relative;
  height: unset !important;
  letter-spacing: 1.5px !important;
  margin-top: 1rem !important;
}

.winGift input[type="submit"]:hover {
  background-image: linear-gradient(rgb(0 0 0 / 10%) 0 0);
}

.winGift button:hover {
  background-color: #52902c;
}

.winGift .dogImage {
  position: relative;
  width: 100%; 
  z-index: 3;
  transform: translateY(-10%);
}

.winGift .formWrapper {
  width: 100%;
}

@media (min-width: 992px) {
  .winGift .winGiftContainer {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
  }

  .winGift .backgroundImage {
    background: url('../images/wingift-background.jpg') no-repeat center center/cover;
  }

  .winGift p {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .winGift input[type="email"] {
    font-size: 1rem !important;
    padding: 0.75rem 2.5rem !important;
  }

  .winGift input[type="submit"] {
    font-size: 1.25rem !important;
  }

  .mc-field-group.d-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .winGift label {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .winGift .formContainer {
    width: 60%;
    padding: 0;
    padding: 100px;
  }

  .winGift .formWrapper {
    width: 90%;
  }

  .winGift .heartShape {
    position: relative; 
    display: flex;
    justify-content: start;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    min-height: 40vw; 
    background: none;
    background-size: contain; 
  }

  .winGift .dogImage {
    width: 40vw;
    transform: translate(-30%, 0);
  }
}

@media (min-width: 1200px) {
  .winGift .formContainer {
    width: 50%;
  }

  .winGift h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1400px) {
  .winGift h1 {
    font-size: 3rem;
  }
}