.aboutHero {
  width: 100%;  
  background-image: url('../../images/hero@2x-mobile.jpg'); 
  background-size: cover; 
  background-position: center top;
  box-sizing: border-box;
  padding: var(--padding-2x) 0;
  line-height: 1;
}

.aboutHero h1 {
  color: var(--color-teal);
  margin: 0;
  font-weight: 500;
}

.aboutHero h2 {
  font-weight: 800;
  color: var(--color-teal);
  margin: 0;
  font-size: 3rem;
}

.mobileText {
  background-color: var(--color-teal);
  width: 100%;
  color: white;
  padding: var(--padding-default) 0;
}

.mobileText h2 {
  font-weight: 800;
  color: white;
  margin: 0;
  font-size: 3rem;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .aboutHero {
    background-image: url('../../images/hero@2x.jpg'); 
    background-position: center center;
  }

}

/* Mobile */
@media screen and (max-width: 767px) {
  .aboutHero {
    height: 90vw;
  }
}