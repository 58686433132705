.faqHero {
  padding: var(--padding-default) 0;
  background-image: url("../../images/faqhero3@2x.jpg");
  background-size: cover; 
  background-position: top;
  height: 110vw;
  display: flex;
  align-items: end;
}

.faqHero h1 {
  margin: 0;
  font-weight: 800;
  font-family: var(--font-forma-djr-display);
  color: white;
  line-height: 1;
}

/* Desktop */
@media screen and (min-width: 576px) {
  .faqHero {
    background-image: url("../../images/faqhero@2x.jpg");
    background-size: cover; 
    background-position: center; 
    height: unset;
  }
}