.aboutMadelene {
  color: white;
  background-color: var(--color-mediumseagreen);
  box-sizing: content-box;
  overflow: hidden;
  padding: var(--padding-default) 0;
  position: relative;
  z-index: 1;
}

.aboutMadelene .container {
  
}

.aboutMadelene img {
  width: 100%;
}

.aboutMadelene .tagline {
  text-align: center;
}

.aboutMadelene .tagline h3 {
  font-size: 3rem;
  font-family: var(--font-felt-tip-roman);
}

.aboutMadelene p {
  margin-bottom: 2rem;
}

.aboutMadelene .abstractBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  height: auto;
  overflow: hidden;
  z-index: -1;
}

/* Mobile */
@media screen and (max-width: 767px) {
  .aboutMadelene .abstractBg {
    top: 0;
    left: 0;
    width: 100%;
  }
}
