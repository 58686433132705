.subscribe {
  padding: var(--padding-default) 0;
  background-color: var(--color-mediumseagreen);
}

.subscribe h2 {
  color: var(--color-teal);
  font-weight: 800;
  font-size: 2rem;
}

.subscribe p {
  color: var(--color-teal);
  font-weight: 600;
  margin-bottom: 2rem;
}

.subscribe label {
  color: var(--color-darkslategray);
  font-weight: 600;
  font-size: 1rem;
  display: block;
  margin-bottom: 4px;
  font-family: var(--font-forma-djr-display);
}

.subscribe input {
  border: none;
  padding: 8px;
  width: 100%;
  border-radius: 0 !important;
  border: none !important;
}

.subscribe input:focus {
    outline: none;
}

.subscribe input[type="submit"] {
  color: white !important;
  background-color: var(--color-teal) !important;
  border: none !important;
  font-weight: 700 !important;
  padding: 10px 100px !important;
  border-radius: 0 !important;
  height: unset !important;
  font-size: 1rem !important;
  letter-spacing: 1.5px !important;
  margin-top: 2rem !important;
}

.subscribe input[type="submit"]:hover {
  background-image: linear-gradient(rgb(0 0 0 / 10%) 0 0);
}

.subscribe form {
  margin: 0 !important;
}