.friends {
  background-color: var(--color-mediumseagreen);
  padding: var(--padding-default) 0;
}

.friends h2 {
  font-size: 3rem;
  color: white;
  font-weight: 700;
}

.friends h3 {
  color: white;
  font-family: var(--font-felt-tip-roman);
  font-size: 2.5rem;
  margin-bottom: 32px;
}

.friends p {
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 40px;
}

.friends img {
  width: 80%;
  top: 0;
  left: 55%;
}

.friends img:nth-child(2) {
  margin-top: -15%;
}

/* Desktop */
@media screen and (min-width: 992px) {
  .friends img {
    width: 60%;
    top: 0;
    left: 55%;
  }
  
  .friends img:nth-child(2) {
    margin-left: 40%;
    margin-top: -30%;
  }
  
}
