.notFound {
  color: var(--color-teal);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.notFound img {
  width: 100%;
  transform: scale(1.01);
}

.notFound h1 {
  font-weight: 800;
}

.notFound h2 {
  font-weight: 700;
}

.notFound a {
  color: var(--color-teal);
  font-weight: 700;
  text-decoration: none;
}

@media screen and (min-width: 767px) {
  .notFound {
    background: linear-gradient(to right, white 50%, var(--color-teal) 50%);
  }
}

