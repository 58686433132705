@font-face {
  font-family: 'Felt Tip Roman';
  src: url('../public/fonts/FeltTipRomRegular.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Felt Tip Roman';
  src: url('../public/fonts/FeltTipRomBold.TTF') format('truetype');
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'Felt Tip Roman';
  src: url('../public/fonts/FeltTipRomHeavy.TTF') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-Hairline.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-HairlineItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-ThinItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-ExtraLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-ExtraLightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-LightItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-MediumItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Forma DJR Display";
  src: url('../public/fonts/FormaDJRDisplay-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* Montserrat */
@font-face {
  font-family: 'Montserrat';
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap') format('woff2');
  font-weight: bold;
  font-style: normal;
}

/* Font Awesome 5 Free */
@font-face {
  font-family: 'Font Awesome 5 Free';
  src: url('https://use.fontawesome.com/releases/v5.15.4/webfonts/fa-solid-900.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* Font Awesome 5 Brands */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  src: url('https://use.fontawesome.com/releases/v5.15.4/webfonts/fa-brands-400.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

:root {
  /* fonts */
  --font-forma-djr-display: "Forma DJR Display";
  --font-font-awesome-5-free: "Font Awesome 5 Free";
  --font-font-awesome-5-brands: "Font Awesome 5 Brands";
  --font-felt-tip-roman: "Felt Tip Roman";
  --font-montserrat: Montserrat;

  /* Colors */
  --color-white: #fff;
  --color-mediumseagreen: #6ac173;
  --color-teal: #086148;
  --color-darkslategray: #3a3a3a;

  /* Paddings */
  --padding-default: 4rem;
  --padding-2x: 8rem;
  --padding-3x: 12rem;
  --padding-4x: 16rem;
  --padding-5x: 20rem;
  

  font-size: 14px;
  font-family: var(--font-forma-djr-display);
}

body {
  margin: 0;
  font-weight: 500;
  letter-spacing: 1.5px;
  font-size: 1rem;;
  font-family: var(--font-forma-djr-display);
}

p, span, div, a {
  line-height: 1.5;
  font-weight: 500;
  font-size: 1.2rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 1.75rem;
}

strong, b {
  font-weight: 800 !important;
}

a.button {
  display: inline-block; 
  padding: 10px 35px; 
  background-color: white;
  color: var(--color-teal); 
  text-decoration: none; 
  font-weight: 800;
  font-size: 1.5rem;
}

a.button:hover {
  background-image: linear-gradient(rgb(0 0 0 / 10%) 0 0);
}

a.button.button-reversed {
  color: white !important;
  background-color: var(--color-teal) !important;
}

a.button.button-big {
  padding: 15px 35px; 
}

ul {
  list-style: none;
  padding: 0;
}

@media screen and (min-width: 992px) {
  :root {
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) {
  :root {
    font-size: 16px;
  }
}
