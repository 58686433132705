.homeHero {
  width: 100%; 
  background-image: url('../../images/heroimagemobile-1@2x.jpg'); 
  background-size: cover; 
  background-position: 0 bottom;
  display: flex;
  align-items: start;
  color: white;
  padding: var(--padding-default) 0 0 0;
  height: 90vh;
}

.homeHero h1, .homeHero p, .homeHero a {
  margin: 10px 0; 
}

.homeHero h1 {
  font-weight: 500;
  line-height: 1;
}

.homeHero h1 span.heavy {
  font-weight: 800;
  font-size: 3rem;
}

.homeHero p {
  font-size: 1.33rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.homeHero a {
  padding: 15px 35px;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .homeHero h1 {
    font-size: 4rem;
    font-weight: 600;
  }

  .homeHero h1 span.heavy {
    font-size: 4rem;
  }

  .homeHero p {
    width: 30%;
  }

  .homeHero {
    background-image: url('../../images/heroimage-7@2x.jpg'); 
    padding: var(--padding-3x) 0;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .homeHero a {
    width: 80%;
  }
}

@media screen and (max-width: 575px) {
  .homeHero h1, .homeHero h1 span.heavy {
    font-size: 2.5rem;
  }
}