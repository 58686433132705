.product {
  padding: var(--padding-default) 0;
}

.product h1 {
  color: var(--color-teal);
  font-weight: 800;
  margin-bottom: 1.5rem;
}

.product .informationRow p {
  color: var(--color-teal);
  font-weight: 800;
}

.product .informationRow span {
  color: var(--color-mediumseagreen);
}

.product .informationRow div {
  color: var(--color-darkslategray);
}

.product .informationRow .informationItem {
  margin-top: 24px;
  margin-bottom: 12px;
}

.product .informationRow .informationText {
  display: inline;
}

.product .dot {
  display: none !important;
}

:global(.slider-wrapper) {
  border: 1px var(--color-teal) solid;
}

:global(.thumb.selected) {
  border: 1px var(--color-teal) solid !important;
}

:global(.thumb:hover) {
  border-color:var(--color-teal) !important;
}

:global(.thumb) {
    max-width: calc(100% / 7 - 6px) !important;
}

:global(.thumbs) {
  padding: 0 !important;
  transform: none !important;
}

:global(.thumbs-wrapper) {
  margin: 40px 0 20px 0 !important;
}