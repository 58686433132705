.navbar {
  background-color: var(--color-teal);
  padding: 1rem;
  position: relative;
  margin-bottom: -1px;
}

.navbar .logo {
  max-width: 200px;
  width: 100%;
  height: auto;
}

.navbar a {
  text-decoration: none;
  padding: 6px;
  color: white;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 1rem;
  min-width: 44px;
  min-height: 44px;
  display: flex;
  align-items: center;
}

.navbar a:hover {
  transform: scale(1.05);
}

.menuButton {
  display: none;
  color: white;
  font-size: 3rem;
}

.menuButton:hover {
  cursor: pointer;
}

.menu {
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  z-index: 100;
  min-height: 100%;
}

@media (max-width: 767px) {
  .navbar .logo {
    max-width: 150px;
  }

  .menuButton {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(100%, -50%); 
  }

  .menu {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    width: 100%;
    background-color: var(--color-teal);
    padding: 10px;
  }

  .navbar a:hover {
    transform-origin: center left;
  }
}
