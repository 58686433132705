.productPreview {
  text-align: center;
}

.productPreview img {
  width: 75%;
}

.productPreview h1 {
  color: var(--color-teal);
  font-weight: 600;
  margin-top: 8px;
  font-size: 1.75rem;
}

.productPreview .button {
  display: inline-block; 
  padding: 15px 35px; 
  color: white;
  background-color: var(--color-teal); 
  text-decoration: none; 
  font-weight: 700;
  width: 50%;
  margin-top: 16px;
}

.productPreview .button:hover {
  background-image: linear-gradient(rgb(0 0 0 / 10%) 0 0);
}
