.usp {
  width: 100%;  
  padding: var(--padding-default) 0 0 0;
}

.usp ul {
  list-style: none;
}

.usp ul li img {
  width: 100px;
  margin-top: 2rem;
}

.usp h2 {
  margin-top: 0;
  color: var(--color-darkslategray);
  font-weight: 600;
  font-size: 2rem;
}

.usp .highlight, h3 {
  color: var(--color-teal);
  font-size: 2rem;
}

.usp h3 {
  font-size: 2rem;
  font-family: var(--font-felt-tip-roman);
  margin: 0;
  margin-bottom: 0.5rem;
}

.usp .highlight {
  font-weight: 800;
}

.usp p {
  color: var(--color-darkslategray);
  margin: 0;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .usp {
    background-image: url('../../images/new-dog-banner.jpg'); 
    background-size: cover;
    background-position: center right;
    padding: var(--padding-default) 0;
  }

  .usp ul li {
    display: flex;
    align-items: center;
  }
  
  .usp ul li img {
    margin-right: 16px;
  }
}

/* Mobile */
@media screen and (max-width: 767px) {
  .bg {
    height: 140vw;
    background-image: url('../../images/new-dog-banner-mobile.jpg'); 
    background-size: cover;
    background-position: center bottom;
  }
}