.contactHero {
  padding: var(--padding-5x) 0 var(--padding-default) 0;
  background-image: url("../../images/hero2@2x.jpg");
  background-size: cover; 
  background-position: center top; 
}

.contactHero h1 {
  margin: 0;
  font-weight: 800;
  font-family: var(--font-forma-djr-display);
  color: white;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .contactHero {
    background-image: url("../../images/hero1@2x.jpg");
    padding: var(--padding-2x) 0;
  }
}