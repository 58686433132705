.aboutKajsa {
  color: white;
  background-color: var(--color-mediumseagreen);
  box-sizing: content-box;
  overflow: hidden;
  background-position-x: 85%;
  padding: var(--padding-default) 0 0 0;
  position: relative;
  z-index: 1;
}

.aboutKajsa img {
  width: 100%;
}

.aboutKajsa h2 {
  font-weight: 800;
  margin-bottom: 2rem;;
}

.aboutKajsa p {
  margin-bottom: 2rem;
}

.aboutKajsa img.abstractBg {
  position: absolute;
  top: 0;
  left: 45%;
  width: 60%;
  height: auto;
  object-fit: unset;
  overflow: hidden;
  z-index: -1;
}

.aboutKajsa img.abstractBgMobile {
  position: absolute;
  bottom: -2rem;
  right: 0;
  width: 100%;
  height: auto;
  object-fit: unset;
  overflow: hidden;
  z-index: -1;
}