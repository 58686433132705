.products {
  padding: var(--padding-default) 0;
}

.products h1 {
  font-weight: 800;
  color: var(--color-teal);
  margin-bottom: 2rem;
}

.products .ingress {
  color: var(--color-darkslategray);
  font-size: 1rem;
}

.products .tagline {
  color: var(--color-teal);
  font-family: var(--font-felt-tip-roman);
  font-size: 2.5rem;
  font-weight: 500;
}