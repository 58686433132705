.expandableRow {

}

.expandableRow .title, .expandableRow .icon {
  color: var(--color-mediumseagreen);
  margin-bottom: 0;
}

.expandableRow .content {
  color: var(--color-teal);
  overflow: hidden;
  transition: height 0.3s ease;
}

.expanded {
  height: auto;
}

.collapsed {
  height: 0;
}

.expandableRow h3, .expandableRow p {
  font-weight: 600;
}

.expandableRow .icon {
  font-size: 1.75rem;
  font-weight: 800;
  margin-top: -8px;
  user-select: none;
}

.expandableRow hr.separator {
  border-top: solid 2px var(--color-teal);
  opacity: 1;
  margin-top: 8px;
}

.accordion {
  min-height: 44px;
  display: flex;
  align-items: center;
}