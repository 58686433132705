.bullySticks {
  text-align: center;
  width: 100%;
  background-color: var(--color-teal);
  color: white;
  padding: var(--padding-default) 0 0 0;
}

.bullySticks .highlight {
  color: var(--color-mediumseagreen);
  font-weight: 800;
  font-size: 2rem;
}

.bullySticks h3 {
  color: white;
  font-family: var(--font-felt-tip-roman);
}

.bullySticks h2 {
  font-size: 2rem;
}

.bullySticks .items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bullySticks .items:first-of-type {
  margin-bottom: 40px;
}

.bullySticks .item {
  width: 250px;
}

.bullySticks .item img {
  width: 100px;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .bullySticks {
    background-image: url('../../images/untitled-design2-1@2x - desktop.png'); 
    background-size: 50% auto;
    background-repeat: no-repeat; 
    background-position: center bottom;
    padding: var(--padding-default) 0;
  }
}

/* Mobile */
@media screen and (max-width: 767px) {
  .bg {
    background-image: url('../../images/untitled-design2-1@2x.png'); 
    background-size: cover;
    background-position: center bottom;
    height: 100vw;
    display: flex;
    align-items: end;
    justify-content: center;
  }

  .bg a {
    margin-bottom: 3rem;
  }

  .bullySticks .items {
    flex-direction: column;
  }

  .bullySticks .item {
    margin-top: 2rem;
  }
}