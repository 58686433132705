.connect {
  padding: var(--padding-default) 0;
}

.connect p {
  font-weight: 500;
  color: var(--color-teal);
}

.connect a {
  color: var(--color-teal);
  font-weight: 800;
  font-size: 1.33rem;
  margin-bottom: 1rem;
  display: block;
}

.connect .socials {
  color: var(--color-mediumseagreen);
  font-size: 3rem;
  font-family: var(--font-felt-tip-roman);
  margin-bottom: 1rem;
  display: inline-block;
}

.connect .icon {
  color: var(--color-mediumseagreen);
  font-size: 3rem;
  margin-left: 8px;
  display: inline;
}

.connect .icon:hover {
  transform: scale(1.1);
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.mailLink {
  min-height: 44px;
  display: flex;
  align-items: center;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .connect .socials, .connect .icon {
    font-size: 2rem;
  }
}