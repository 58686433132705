.footer {
  background-color: #086148;
  padding: var(--padding-default) 0;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  color: white;
}

.footer a {
  color: white;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 500;
  min-width: 44px;
  min-height: 44px;
  display: flex;
  align-items: center;
}

.footer a:hover {
  transform: scale(1.05);
}

.footer .icon {
  width: 42px;
  margin-left: -9px;
}

.footerHeading {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 4px;
}

.contact {
  display: flex;
  align-items: center;
}

.footer img {
  width: 100%;
  max-width: 200px;
}

.footer li {
  margin-bottom: 4px;
}

.footer ul {
  margin: 0;
}

.footer input[type="email"] {
  border: 1px solid var(--color-teal);
  padding: 0.5rem 2rem;
  font-size: 1rem;
  width: 100%;
  letter-spacing: 1px;
  font-weight: 500;
  color: var(--color-mediumseagreen);
  border-radius: 1px !important;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.footer input::placeholder {
  color: var(--color-mediumseagreen);
}

.footer  input:focus {
    outline: none;
}

.footer input[type="checkbox"] {
  margin-right: 1rem;
}

.footer  input[type="submit"] {
  color: white;
  background-color: unset !important;
  border: none !important;
  border-radius: 0 !important;
  height: unset !important;
  letter-spacing: 1.5px !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.footer input[type="submit"]:hover {
  transform: scale(1.05);
}

/* Desktop */
@media screen and (min-width: 768px) {
  .footer h4, .footer a {
    font-size: 1rem;
  }
}
