.contactForm {
  padding: var(--padding-default) 0;
  background-color: var(--color-mediumseagreen);
}

.contactForm  h2 {
  color: var(--color-teal);
  font-weight: 800;
  margin-bottom: 32px;
  font-size: 2rem;
}

.contactForm  p {
  color: var(--color-teal);
  font-weight: 600;
  margin-bottom: 32px
}

.contactForm  label {
  color: var(--color-darkslategray);
  font-weight: 600;
  font-size: 1rem;
  display: block;
  margin-bottom: 4px;
  font-family: var(--font-forma-djr-display);
}

.contactForm  input, .contactForm textarea {
  border: none;
  padding: 8px;
  width: 100%;
  margin-bottom: 1rem;
}

.contactForm textarea {
  height: 120px;
}

.contactForm input:focus, .contactForm textarea:focus {
    outline: none;
}

.contactForm button {
  color: white;
  background-color: var(--color-teal);
  border: none;
  margin-top: 18px;
  font-weight: 800;
  padding: 10px 100px;
  font-size: 1rem;
  letter-spacing: 1.5px;
}

.contactForm button:hover {
  background-image: linear-gradient(rgb(0 0 0 / 10%) 0 0);
}

